import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We regularly publish updates to documentation housed at `}<a parentName="p" {...{
        "href": "/"
      }}>{`docs.fastly.com`}</a>{`. Changes for the current month appear immediately below and we make older items available separately in our `}<a parentName="p" {...{
        "href": "/changes/archive"
      }}>{`changelog archive`}</a>{`.`}</p>
    <p>{`You can subscribe to email updates of these changes using the form on this page. We also make the changes available via a `}<a parentName="p" {...{
        "href": "/changes/rss.xml"
      }}>{`machine-readable feed`}</a>{` for use with RSS-compatible software.`}</p>
    <p>{`Looking for a list of the most recent changes significant to our terms of service? Check out the `}<a parentName="p" {...{
        "href": "/changes/significant/"
      }}>{`significant changelog`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LinkDataURL = makeShortcode("LinkDataURL");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In conjunction with regular and significant changelog updates, we also make available PDF `}<LinkDataURL to="snapshots-archive" mdxType="LinkDataURL">{`content snapshots`}</LinkDataURL>{` of docs.fastly.com at specific, regular points in time as part of our `}<LinkDataURL to="docs-archive" mdxType="LinkDataURL">{`Documentation Archives`}</LinkDataURL>{` where we also house current and prior versions of our legal terms, statements, and policies.`}</p>
    <p>{`Fastly's documentation is formally presented in the English language at docs.fastly.com. Where applicable, `}<a parentName="p" {...{
        "href": "/translations/"
      }}>{`translations`}</a>{` of that content typically appear within one month of the publication of the English version. Translations are provided as a convenience only and are not archived.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      